import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Hero from '../components/Hero.js'
import 'react-lazy-load-image-component/src/effects/blur.css'

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <Hero />
        <div id="index">
          <section id="motto">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="motto-content text-center">
                    <h2 className="motto-title text-white">
                      Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to make a type specimen
                      book.
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="service">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="service-content">
                    <h2 className="section-title text-blue text-center">OUR</h2>
                    <h3 className="sub-title text-center">Services</h3>
                    <p className="section-p text-center mt-4">
                      Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s.
                    </p>
                  </div>
                  <div className="service-list mt-5">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="list-content text-center">
                          <img
                            src="/img/trial.png"
                            className="img-fluid mb-3"
                            alt="Trial"
                          />
                          <h5 className="service-title text-blue">
                            Service Offered 1
                          </h5>
                          <p className="section-p mb-0">
                            Lorem Ipsum has been the industry's standard dummy
                            text ever since the 1500s, when an unknown printer.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="list-content text-center">
                          <img
                            src="/img/lawyer.png"
                            className="img-fluid mb-3"
                            alt="Lawyer"
                          />
                          <h5 className="service-title text-blue">
                            <strong>SERVICE OFFERED 2</strong>
                          </h5>
                          <p className="section-p mb-0">
                            Lorem Ipsum has been the industry's standard dummy
                            text ever since the 1500s, when an unknown printer.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="list-content text-center">
                          <img
                            src="/img/constitution.png"
                            className="img-fluid mb-3"
                            alt="Constitution"
                          />
                          <h5 className="service-title text-blue">
                            <strong>SERVICE OFFERED 3</strong>
                          </h5>
                          <p className="section-p mb-0">
                            Lorem Ipsum has been the industry's standard dummy
                            text ever since the 1500s, when an unknown printer.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="list-content text-center">
                          <img
                            src="/img/legal-paper.png"
                            className="img-fluid mb-3"
                            alt="Service Offered"
                          />
                          <h5 className="service-title text-blue">
                            <strong>SERVICE OFFERED 4</strong>
                          </h5>
                          <p className="section-p mb-0">
                            Lorem Ipsum has been the industry's standard dummy
                            text ever since the 1500s, when an unknown printer.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="about">
            <div className="container">
              <div className="lawyer-img">
                <img
                  src="/img/lawyer-img.png"
                  className="img-fluid"
                  alt="Lawyers"
                />
              </div>
              <div className="row">
                <div className="col-md-6" />
                <div className="col-md-6">
                  <div className="about-content">
                    <h2 className="section-title text-blue">About Us</h2>
                    <h3 className="sub-title">What is Lorem Ipsum?</h3>
                    <p className="section-p mt-4">
                      Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to make a type specimen
                      book. It has survived not only five centuries, but also
                      the leap into electronic typesetting, remaining
                      essentially unchanged. It was popularised in the 1960s
                      with the release of Letraset sheets containing Lorem Ipsum
                      passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
