import React from 'react'
import { Link } from 'gatsby'

const Hero = () => (
  <hero>
    <div id="hero">
      <div className="container">
        <div className="row">
          <div className="col-md-6" />
          <div className="col-md-6">
            <div className="hero-content">
              <h1 className="hero-title text-white">Lorem Ipsum</h1>
              <hr />
              <p className="hero-p">
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book. It has survived
                not only five centuries, but also the leap into electronic
                typesetting, remaining essentially unchanged.
              </p>
              <div className="cta-link">
                <Link to="#" className="cta-hero mt-4">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </hero>
)

export default Hero
